import { virtualTrack, primeInviewEvent } from './tracking';
import { handleAdBlockDependentAction } from './adblock';

/**
 * @see {@link useLegacyFunction} see the comment in the React hook for details
 */
(() => {
	window.legacyFunctions = {
		handleAdBlockDependentAction,
		virtualTrack,
		primeInviewEvent,
	};

	window.dispatchEvent(new CustomEvent('legacyFunctionsLoaded'));
})();
